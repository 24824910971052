import Rails from '@rails/ujs'
import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    static targets = [ "source" ]
    connect() {
        if (document.queryCommandSupported("copy")) {
            this.element.classList.add("clipboard--supported")
        }
    }

    // copy() {
    //   this.sourceTarget.select()
    //   document.execCommand("copy")
    // }

    copy (event) {
        Rails.stopEverything(event)
        const value = this.sourceTarget.value
        const disabled = !!this.sourceTarget.disabled
        this.sourceTarget.removeAttribute('disabled')
        this.sourceTarget.disabled = false
        this.sourceTarget.select()
        document.execCommand('copy')
        this.sourceTarget.value = 'Copied...'
        setTimeout(
            (() => {
                this.sourceTarget.selectionStart = this.sourceTarget.selectionEnd
                if (disabled) this.sourceTarget.setAttribute('disabled', disabled)
                this.sourceTarget.disabled = disabled
            }).bind(this),
            10
        )
        setTimeout((() => (this.sourceTarget.value = value)).bind(this), 1000)
    }

}
